import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { useRecoilValue } from "recoil";

import tenantAtom from "@/recoil/tenant";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";
export interface Props {
  className?: String;
}

const LogoutButton: React.FunctionComponent<Props> = ({ className }) => {
  const tenant = useRecoilValue(tenantAtom);
  const { domain } = getOrgAuth0ConfigByTenantId(tenant?.id);
  const { logout } = useAuthState(domain);

  const handleLogout = async () => {
    await logout("/");
  };

  return (
    <>
      <button
        className={clsMerge(
          "inline-flex h-10 w-full flex-col items-center justify-center rounded ",
          "border border-slate-800 px-4 text-center text-sm font-medium ",
          "leading-tight text-tenant-blue-dark",
          className,
        )}
        onClick={handleLogout}
      >
        Sign Out
      </button>
    </>
  );
};

export default LogoutButton;
