import { clsMerge } from "@artifactlabs/shared-react-utils";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  Fragment,
  HTMLAttributes,
  LabelHTMLAttributes,
  FunctionComponent,
  useMemo,
  useState,
} from "react";

import ChevronArrowDown from "@/components/Icons/ChevronArrowDown";

export type FormSelectOption = {
  id: string;
  name: string;
};

interface FormSelectWithLabelProps {
  selected: string;
  handleChange: (...args: any) => void;
  options: FormSelectOption[];
  label: string;
  disabled?: boolean;
  inlineHelperText?: string;
  error?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: any;
  errorProps?: HTMLAttributes<HTMLDivElement>;
  isOptional?: boolean;
  mode?: "default" | "label-on-border";
}

const FormSelectWithLabel: FunctionComponent<FormSelectWithLabelProps> = ({
  selected,
  handleChange,
  disabled = false,
  options,
  label,
  inlineHelperText,
  error,
  labelProps,
  errorProps,
  isOptional = false,
  mode = "default",
}) => {
  const { className: labelClassNames, ...labelRestProps } = labelProps ?? {};
  const { className: errorClassName, ...errorRestProps } = errorProps ?? {};
  const [onSelected, setOnSelected] = useState(Boolean(selected));

  const selectedOptions = useMemo(
    () => options.find(each => each.name === selected),
    [options, selected],
  );

  return (
    <>
      <Listbox
        disabled={disabled}
        value={selectedOptions}
        onChange={newValue => {
          setOnSelected(true);
          handleChange(newValue);
        }}
      >
        {({ open }) => (
          <>
            {mode === "label-on-border" ? (
              <Listbox.Label
                className={clsMerge(
                  "relative bottom-[-14px] z-[1] ml-[10px] bg-white px-[6px] py-0 text-[12px] text-sm font-medium leading-[20px] text-tenant-gray-dark",
                  !onSelected && "opacity-0",
                )}
              >
                {label}
                {isOptional && <span className="ml-1 text-tenant-gray-medium">(Optional)</span>}
              </Listbox.Label>
            ) : (
              <Listbox.Label className="inline-flex h-5 items-center justify-start gap-1 self-stretch">
                <label
                  className={clsMerge(
                    "text-sm font-medium leading-[20px] text-tenant-gray-dark",
                    labelClassNames,
                  )}
                  {...labelRestProps}
                >
                  {label}
                  {isOptional && <span className="ml-1 text-tenant-gray-medium">(Optional)</span>}
                  <span className="ml-1 text-sm font-medium text-tenant-gray-medium">
                    {inlineHelperText}
                  </span>
                </label>
              </Listbox.Label>
            )}

            <div className="relative mt-2">
              <Listbox.Button
                className={clsMerge(
                  "h-10 w-full self-stretch rounded-[8px] border-none bg-white p-2 text-left ring-1",
                  open ? "ring-tenant-grey-midnight-blue" : "ring-tenant-gray-lighter",
                  !onSelected && "ring-opacity-[0.125]",
                  "focus:outline-none focus:ring-[1px] focus-visible:outline-none",
                  disabled &&
                    "cursor-default bg-tenant-gray-lighter text-tenant-gray-steel ring-1 ring-tenant-gray-lighter focus:ring-[0px]",
                )}
                tabIndex={0}
              >
                <span
                  className={clsMerge(
                    "block truncate pl-[8px] text-sm",
                    !selectedOptions?.name && "text-tenant-gray-medium",
                  )}
                >
                  {selectedOptions?.name ?? (mode === "label-on-border" ? label : "Select...")}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronArrowDown
                    aria-hidden="true"
                    className={clsMerge(
                      "h-4 w-4 text-tenant-gray-dark",
                      disabled && "text-tenant-gray-steel",
                    )}
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-[8px] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map(each => (
                    <Listbox.Option
                      className={({ active }) =>
                        clsMerge(
                          active ? "bg-tenant-gray-lightest" : "text-tenant-gray-dark",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                      key={each.id}
                      value={each}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={clsMerge(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {each.name}
                          </span>

                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-tenant-gray-dark">
                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {Boolean(error) && (
        <p
          className={clsMerge("mt-2 text-xs font-normal text-red-600", errorClassName)}
          {...errorRestProps}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default FormSelectWithLabel;
