/* eslint-disable react/no-unescaped-entities */
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import StripeEmbeddedCheckout from "@/components/Checkout/stripe/StripeEmbeddedCheckout";
import { useCloseModal } from "@/hooks/useModal";
import { useWindowResize } from "@/hooks/useWindowResize";

interface CheckOutModalViewProps {
  totalPrice: number;
  extIds: [string];
}

const CheckOutModalView: FunctionComponent<CheckOutModalViewProps> = ({ totalPrice, extIds }) => {
  //
  const closeModal = useCloseModal();
  const isMobile = useWindowResize();

  return (
    <div
      className={clsMerge(
        "overflow-y-auto bg-white",
        "h-[100vh] min-w-[100vw] px-[30px] pt-[15px]",
        "md:block md:h-fit md:min-w-fit md:rounded-xl md:p-10 md:px-0",
      )}
    >
      {isMobile && (
        <div className="absolute right-6 top-6">
          <FontAwesomeIcon
            className="h-[24px] w-[24px] cursor-pointer text-[#060606]"
            icon={faClose}
            onClick={closeModal}
          />
        </div>
      )}
      <div className="h-fit overflow-y-auto md:w-[460px]">
        <StripeEmbeddedCheckout extIds={extIds} totalPrice={totalPrice} />
      </div>

      {/*  */}
      <div className="pb-[40px] md:pb-[0px]" />
    </div>
  );
};

export default CheckOutModalView;
