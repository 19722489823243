import { clsMerge } from "@artifactlabs/shared-react-utils";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { useRecoilValue } from "recoil";

import { CheckOutModalView } from "./CheckOutModalView";
import { GenericModalView } from "./GenericModalView";
import { RequestPaymentModalView } from "./RequestPaymentModalView";
import { SearchbarFilterModalView } from "./SearchbarFilterModalView";
import UserProfileModalView from "./UserProfileModalView";

import { useCloseModal } from "@/hooks/useModal";
import UIAtom from "@/recoil/UI";

const ModalView: React.FunctionComponent<{
  open: boolean;
  modalView: string;
  modalProps: any;
  isClosable?: boolean;
  closeModal: (...args: any) => void;
}> = ({ open, modalView, closeModal, modalProps, isClosable = true }) => {
  //
  return (
    <Transition.Root as={React.Fragment} show={open}>
      <Dialog
        as="div"
        className="relative z-100"
        open={open}
        onClose={() => {
          isClosable ? closeModal() : "";
        }}
      >
        {/* Backdrop */}
        <div
          aria-hidden="true"
          className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm backdrop-filter"
          onClick={() => {
            isClosable ? closeModal : "";
          }}
        />

        {/* Full-screen scrollable container */}
        <div className={clsMerge("fixed inset-0 h-screen w-screen")}>
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center">
            {/* The actual dialog panel  */}
            <Dialog.Panel className={clsMerge("h-fit w-fit")}>
              {modalView === "USER_PROFILE" && <UserProfileModalView {...modalProps} />}
              {modalView === "REQUEST_PAYMENT" && <RequestPaymentModalView {...modalProps} />}
              {modalView === "CHECKOUT_STRIPE" && <CheckOutModalView {...modalProps} />}
              {modalView === "GENERIC_MODAL" && <GenericModalView {...modalProps} />}
              {modalView === "SEARCHBAR_FILTER" && <SearchbarFilterModalView {...modalProps} />}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

// eslint-disable-next-line react/no-multi-comp
const Modal: React.FunctionComponent = () => {
  const UI = useRecoilValue(UIAtom);
  const closeModal = useCloseModal();

  return (
    <ModalView
      closeModal={closeModal}
      isClosable={UI.isClosable}
      modalProps={UI.modalProps}
      modalView={UI.modalView}
      open={UI.displayModal}
    />
  );
};

export default Modal;
