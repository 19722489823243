import { useLazyQuery, useMutation } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import { FunctionComponent, HTMLAttributes, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import "react-international-phone/style.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import * as z from "zod";

import { createUserMutation, getUserQuery } from "@/api/queries/user";
import LicenseLinkButton from "@/components/Button/LicenseLinkButton";
import TermsAndConditionsButton from "@/components/Button/TermsAndConditionsButton";
import CustomPhoneInput from "@/components/CustomPhoneInput";
import FormInputWithLabel from "@/components/Form/FormInputWithLabel";
import FormSelectWithLabel from "@/components/Form/FormSelectWithLabel";
import SquareCheckbox from "@/components/Input/SquareCheckbox";
import { countriesISO3166, industriesOptions } from "@/constants";
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
} from "@/gql/graphql";
import { useCloseModal } from "@/hooks/useModal";
import tenantAtom from "@/recoil/tenant";
import userAtom from "@/recoil/user";
import { getOrgConfigByTenantId } from "@/utils/org";

type FormInputs = {
  firstName: string;
  lastName: string;
  entityName: string;
  entityPhone: string;
  phone: string;
  title: string;
  entityIndustry: string;
  entityCountry: string;
  entityAddress: string;
};

interface UserProfileProps extends HTMLAttributes<HTMLFormElement> {
  defaultFormValues?: FormInputs;
  disabled?: boolean;
  submitButtonText?: string;
  mode?: "default" | "label-on-border";
}

const UserProfile: FunctionComponent<UserProfileProps> = ({
  defaultFormValues,
  submitButtonText = "Create profile",
  disabled = false,
  className,
  mode = "default",
}) => {
  const setUserAtom = useSetRecoilState(userAtom);
  const [getUser] = useLazyQuery<GetUserQuery, GetUserQueryVariables>(getUserQuery, {
    fetchPolicy: "network-only",
  });
  const tenant = useRecoilValue(tenantAtom);
  const config = getOrgConfigByTenantId(tenant.id);
  const schema = z.object({
    firstName: z
      .string()
      .min(1, { message: "First name incomplete" })
      .max(50, { message: "limit to 50 character" }),
    lastName: z
      .string()
      .min(1, { message: "Last name incomplete" })
      .max(50, { message: "limit to 50 character" }),
    entityName: z
      .string()
      .min(1, { message: "Entity associated incomplete" })
      .max(50, { message: "limit to 50 character" }),
    entityPhone: z.string().optional(),
    phone: z.string().min(8, { message: "Phone number incomplete" }),
    title: z
      .string()
      .min(1, { message: "Title/Designation incomplete" })
      .max(50, { message: "limit to 50 character" }),
    entityIndustry: z
      .string()
      .refine(value => industriesOptions.some(option => option.name === value), {
        message: "Industry incomplete",
      }),
    entityCountry: z.string().optional(),
    entityAddress: config.isAddressMandatory
      ? z
          .string()
          .min(1, { message: "Address incomplete" })
          .max(50, { message: "limit to 50 character" })
      : z.string().optional(),
  });

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors, isDirty, isSubmitted },
  } = useForm<FormInputs>({
    mode: "onTouched",
    resolver: zodResolver(schema as any),
    defaultValues: defaultFormValues ?? {
      firstName: "",
      lastName: "",
      entityName: "",
      entityPhone: "",
      phone: "",
      title: "",
      entityIndustry: "",
      entityCountry: "",
      entityAddress: "",
    },
  });

  useEffect(() => {
    if (defaultFormValues) {
      Object.keys(defaultFormValues).forEach((key: any) => {
        setValue(key, (defaultFormValues as any)[key]);
      });
    }
  }, [defaultFormValues, setValue]);

  const closeModal = useCloseModal();
  const selectedEntityIndustry = watch("entityIndustry");
  const selectedEntityCountry = watch("entityCountry");

  const [createAppUser] = useMutation<CreateUserMutation, CreateUserMutationVariables>(
    createUserMutation,
  );

  const onSubmitForm = async (data: FormInputs) => {
    try {
      await createAppUser({
        variables: {
          profile: {
            firstName: data.firstName,
            lastName: data.lastName,
            contactNumber: data.phone,
            entity: {
              addressLineOne: data.entityAddress,
              addressLineTwo: "",
              contactNumber: data.entityPhone,
              country: data.entityCountry,
              industry: data.entityIndustry,
              jobTitle: data.title,
              name: data.entityName,
            },
          },
        },
      });
      const { data: userData } = await getUser();
      if (userData?.user?.profile) {
        setUserAtom(currUserAtom => ({
          ...currUserAtom,
          profile: userData?.user?.profile,
        }));
      }
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onSubmit = handleSubmit(async data => {
    await onSubmitForm(data);
  });

  const shouldShowError = (fieldName: keyof FormInputs) => {
    return isSubmitted && errors[fieldName];
  };

  return (
    <form
      className={clsMerge("mb-[120px] h-fit md:mb-[20px]", className)}
      onSubmit={e => {
        onSubmit(e);
      }}
    >
      <DevTool control={control} placement="top-right" />

      <div className="inline-flex h-fit w-full flex-col items-center justify-start md:max-w-[480px]">
        <div
          className={clsMerge(
            "flex w-full flex-col items-start justify-start",
            `${mode === "default" ? "gap-8" : ""}`,
          )}
        >
          <div className="flex w-full items-start justify-start gap-4">
            <FormInputWithLabel
              error={shouldShowError("firstName") ? errors.firstName?.message : undefined}
              inputProps={{
                id: "firstName",
                ...register("firstName"),
                disabled,
              }}
              label="First name"
              labelProps={{
                htmlFor: "firstName",
              }}
              mode={mode}
            />

            <FormInputWithLabel
              error={shouldShowError("lastName") ? errors.lastName?.message : undefined}
              inputProps={{
                id: "lastName",
                ...register("lastName"),
                disabled,
              }}
              label="Last name"
              labelProps={{
                htmlFor: "lastName",
              }}
              mode={mode}
            />
          </div>

          <div className="inline-flex w-full items-start justify-start">
            <div className="inline-flex w-full flex-col items-start justify-start gap-1">
              <div className="w-full">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onBlur } }) => (
                    <>
                      <CustomPhoneInput
                        defaultCountry="hk"
                        disabled={disabled}
                        isError={Boolean(shouldShowError("phone"))}
                        label="Phone Number"
                        mode={mode}
                        value={getValues("phone")}
                        onBlur={() => {
                          onBlur();
                          trigger("phone");
                        }}
                        onChange={phone => setValue("phone", phone)}
                      />
                      {shouldShowError("phone") && (
                        <p className={clsMerge("mt-2 text-xs font-normal text-red-600")}>
                          {errors.phone?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <FormInputWithLabel
              error={shouldShowError("entityName") ? errors.entityName?.message : undefined}
              inputProps={{
                id: "entityName",
                ...register("entityName"),
                disabled,
              }}
              label="Entity associated"
              labelProps={{
                htmlFor: "entityName",
              }}
              mode={mode}
            />
          </div>

          <div className="w-full">
            <FormInputWithLabel
              error={shouldShowError("title") ? errors.title?.message : undefined}
              inputProps={{
                id: "title",
                ...register("title"),
                disabled,
              }}
              label="Title/Designation"
              labelProps={{
                htmlFor: "title",
              }}
              mode={mode}
            />
          </div>

          {config.isAddressMandatory && (
            <div className="w-full">
              <FormInputWithLabel
                error={shouldShowError("entityAddress") ? errors.entityAddress?.message : undefined}
                inputProps={{
                  id: "entityAddress",
                  ...register("entityAddress"),
                  placeholder: "Address line 1",
                  disabled,
                }}
                label="Address"
                labelProps={{
                  htmlFor: "entityAddress",
                }}
                mode={mode}
              />
            </div>
          )}

          <div className="w-full">
            <FormSelectWithLabel
              disabled={disabled}
              error={shouldShowError("entityIndustry") ? errors.entityIndustry?.message : undefined}
              handleChange={option =>
                setValue("entityIndustry", option.name, {
                  shouldValidate: true,
                })
              }
              label="Industry of the entity"
              labelProps={{
                htmlFor: "entityIndustry",
              }}
              mode={mode}
              options={industriesOptions}
              selected={selectedEntityIndustry}
            />
          </div>

          <div className="w-full">
            <FormSelectWithLabel
              disabled={disabled}
              error={shouldShowError("entityCountry") ? errors.entityCountry?.message : undefined}
              handleChange={option => setValue("entityCountry", option.name)}
              isOptional={true}
              label="Country of entity"
              labelProps={{
                htmlFor: "entityCountry",
              }}
              mode={mode}
              options={countriesISO3166}
              selected={selectedEntityCountry}
            />
          </div>

          {!config.isAddressMandatory && (
            <div className="w-full">
              <FormInputWithLabel
                error={shouldShowError("entityAddress") ? errors.entityAddress?.message : undefined}
                inputProps={{
                  id: "entityAddress",
                  ...register("entityAddress"),
                  disabled,
                }}
                label="Entity address"
                labelProps={{
                  htmlFor: "entityAddress",
                }}
                mode={mode}
              />
            </div>
          )}

          <div className="inline-flex w-full items-start justify-start">
            <div className="inline-flex w-full flex-col items-start justify-start gap-1">
              <div className="w-full">
                <CustomPhoneInput
                  defaultCountry="hk"
                  disabled={disabled}
                  isOptional={true}
                  label="Entity phone number"
                  mode={mode}
                  value={getValues("entityPhone")}
                  onChange={phone => setValue("entityPhone", phone)}
                />
              </div>
            </div>
          </div>
        </div>
        {!disabled && (
          <div className="my-[52px] flex w-[100%] items-center justify-center align-middle">
            <button
              className={clsMerge(
                "flex h-[40px] flex-col rounded-[8px] border border-slate-800 bg-slate-800 px-4",
                "w-full items-center justify-center",
                "items-center justify-center md:w-fit",
                Object.keys(errors).length > 0 ? "opacity-50" : "",
              )}
              // disabled={Object.keys(errors).length > 0}
              type="submit"
            >
              <div className="line-height-[21px] px-[32px] py-[12px] text-center text-[16px] font-medium uppercase text-white">
                {submitButtonText}
              </div>
            </button>
          </div>
        )}

        {!disabled && (
          <div className="flex items-center justify-center gap-2 text-[16px]">
            <SquareCheckbox inputProps={{ required: true }} />
            <div className="font-medium text-zinc-500 md:w-[424px]">
              <span>By registering, you agree to our </span>
              <span className="underline">
                <TermsAndConditionsButton className="px-0 font-medium text-zinc-500 visited:text-zinc-500">
                  Terms and Conditions
                </TermsAndConditionsButton>
              </span>
              <span> and </span>
              <span className="underline">
                <LicenseLinkButton className="px-0 font-medium text-zinc-500 visited:text-zinc-500">
                  License Terms
                </LicenseLinkButton>
              </span>
              <span>. </span>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default UserProfile;
