import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import tenantAtom from "@/recoil/tenant";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";

export interface Props {
  className?: String;
}
const LoginButton: React.FunctionComponent<Props> = ({ className }) => {
  const tenant = useRecoilValue(tenantAtom);
  const { domain } = getOrgAuth0ConfigByTenantId(tenant?.id);
  const { login } = useAuthState(domain);

  const handleClick = useCallback(() => login(), [login]);

  return (
    <>
      <button
        className={clsMerge(
          "block flex cursor-pointer justify-center rounded-[4px] py-2",
          "min-w-[110px] bg-tenant-blue-dark hover:bg-tenant-blue-darker",
          "btn-primary  w-full text-white outline-none",
          className,
        )}
        onClick={handleClick}
      >
        Login
      </button>
    </>
  );
};

export default LoginButton;
