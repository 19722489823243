import { clsMerge } from "@artifactlabs/shared-react-utils";
import React from "react";
import { FunctionComponent, HTMLAttributes } from "react";
import { useRecoilValue } from "recoil";

import tenantAtom from "@/recoil/tenant/atom";
import { getOrgConfigByTenantId } from "@/utils/org";

type Props = HTMLAttributes<HTMLAnchorElement>;

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!;

const LicenseLinkButton: FunctionComponent<Props> = ({ className, children, ...rest }) => {
  //
  const tenant = useRecoilValue(tenantAtom);
  const config = getOrgConfigByTenantId(tenant.id);

  let licenseLink =
    config.licenseLink ??
    `https://${STORAGE_URL}/documents/content-licensing/Artevault_Content+Licensing+Agreement.pdf`;

  //
  return (
    <a
      className={clsMerge(
        "text-center text-sm text-tenant-blue-primary visited:text-tenant-blue-primary md:underline",
        "py-2 pr-2",
        className,
      )}
      {...rest}
      href={licenseLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children ?? "View license terms"}
    </a>
  );
};

export default LicenseLinkButton;
